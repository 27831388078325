//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__bsyc', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      css={style.pageTiles}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme = page.colorTheme ? (page.colorTheme === 'default' ? 'theme1' : page.colorTheme) : 'theme1';  
  //const index = props.idx;
  
  return (
    <div css={style.pageTile__tile} className="pageTile__bsyc">
      <SiteLink className="pageTile__bsyc__content" css={style.tile__content} to={disableLink ? '' : page.pageUrl}>
        <div css={style.tile__flip__wrapper}>

          <div className="pageTile__bsyc__bg" css={style.tile__bg}>
            <div className="pageTile__bsyc__bg2" css={style.tile__bg2}>
              <div className="pageTile__bsyc__bg3" css={style.tile__bg3} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
            </div>
          </div>

          <div css={style.tile__details} className={`pageTile__bsyc__content__details ${colorTheme}`}>
            <div className="pageTile__bsyc__container">
              {
                (page.pageTitle && page.pageTitle !== '') &&
                <div className="pageTile__bsyc__title">
                  {page.pageTitle}
                </div>
              }
              <div className='pageTile__bsyc__logo__img'>
                <img width='100' height='100' src = "/assets/logos/logo3.png" alt ="logo3"/>
              </div>
              <div className="pageTile__bsyc__desc">
                {page.tileDesc || page.description}
              </div>
              <div className="pageTile__bsyc__desc2">
                Find out more
              </div>
            </div>
          </div>

        </div>
      </SiteLink>
    </div>
  )
}

// data-aos={index % 2 === 0 ? 'fade-up-right' : 'fade-up-left'}
// data-aos={index % 2 === 0 ? 'fade-up-left' : 'fade-up-right'}