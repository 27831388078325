import { fb, validators } from '../../../../lib/form';

export function getSignupFormModel(data) {
  
  // let stateList = [
  //   {name: 'State', value: ''},
  //   {name: 'Australian Capital Territory', value: 'ACT'},
  //   {name: 'New South Wales', value: 'New South Wales'},
  //   {name: 'Victoria', value: 'Victoria'},
  //   {name: 'South Australia', value: 'South Australia'},
  //   {name: 'Queensland', value: 'Queensland'},
  //   {name: 'Western Australia', value: 'Western Australia'},
  //   {name: 'Northern Territory', value: 'Northern Territory'},
  //   {name: 'Tasmania', value: 'Tasmania'}
  // ]
  
  const model = fb.group({
    // name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    // phone: [data.phone || '', [], { label: 'Phone', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Enter your email address', type: 'email' }]
    //enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea' }],
    // attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }],
    //state : [data.state || '', [], { label: null, type: 'select', options: stateList }],
  }); 
  return model;
}