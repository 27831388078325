const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  blue : '#5F92FF',
  lightBlue : '#97b8ff',
  yellow : '#FFDC12',
  lightYellow : '#ffed88',
  pink : '#FFC5C9',
  lightPink : '#ffd9dc',
  orange : '#FF7E14',
  lightOrange : '#ffab66',
  purple : '#EE60DD',
  lightPurple : '#f497e9',
  green : '#00D585',
  lightGreen : '#59e4b0'
}

const variables = {
  familyBase: '"sofia-pro",sans-serif',
  familyHeader: '"RecoletaRegular",serif',

  primaryColor: colors.purple,
  fontColor: 'black',
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;