//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles: css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    }),
    pageTile__tile: css(mq({
        flex: '0 0 100%',
        maxWidth: '100%',
        padding: '10px',
        '&:nth-of-type(even)': {
            '.pageTile__bsyc__bg': mq({
                order: ['0', null, '2'],
                '.pageTile__bsyc__bg2' : {
                    left : 'unset',
                    right:'0px'
                }
            }),
            '.pageTile__bsyc__content__details': mq({
                order: '1',
                backgroundColor:variables.blue
            })
        }
    })),

    tile__content: css({
        //     display: 'flex',
        //     flexDirection: 'row',
        //     justifyContent: 'space-between',
        //     width: '100%',
        //     height: '100%',
        //     cursor: 'pointer',
        //     position: 'relative',
        //     flexWrap: 'wrap',
        '&:hover': {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details': {
                //color: 'white',
                //backgroundColor: 'rgba(170, 150, 119,1)'
                // '&.default' : css({
                //      backgroundColor: 'rgba(0,113,188,1)'
                // }),
                // '&.red' : css({
                //      backgroundColor: 'rgba(211,61,2,1)'
                // }),
                // '&.green' : css({
                //      backgroundColor: 'rgba(0, 146, 69,1)'
                // })
            },
            '& .pageTile__bsyc__bg3': {
                transform: 'scale(1.05)'
            },
            '& .pageTile__bsyc__desc2' : {
                textDecoration:'underline'
            }
        }
    }),

    tile__flip__wrapper: css({
        zIndex: '-1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        flexWrap: 'wrap'
    }),

    tile__bg: css(mq({
        height: '0',
        paddingTop: ['80%', '35%', '40%'],
        //height: ['50%','35%','30%'],
        //flex: '1 1 300px',
        flex: ['1 1 100%',null,'1 1 70%'],
        maxWidth: ['100%',null,'70%'],
        overflow: 'hidden',
        position: 'relative'
    })),

    tile__bg2: css(mq({
        height: '100%',
        //backgroundPosition: '50%',
        //backgroundRepeat: 'no-repeat',
        //backgroundSize: 'cover',
        overflow: 'hidden',
        //transition: 'all .2s',
        position: 'absolute',
        top: '0px',
        width: ['100%',null,'97%']
    })),

    tile__bg3: css(mq({
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //overflow:'hidden',
        transition: 'all .2s',
        width: '100%',
        height: '100%'
    })),

    tile__details: css(mq({
        overflow: 'hidden',
        flexGrow: '1',
        textAlign: 'center',
        width: '100%',
        bottom: '0',
        backgroundColor: variables.yellow,
        //     '&.default' : css({
        //           backgroundColor: 'rgba(0, 113, 188,.5)'
        //     }),
        //     '&.red' : css({
        //           backgroundColor: 'rgba(211, 61, 2,.5)'
        //      }),
        //      '&.green' : css({
        //           backgroundColor: 'rgba(0, 146, 69,.5)'
        //      }),
        //backgroundColor : '#77cf22',
        //flex: '1 1 300px',

        // '&.theme1' : {
        //     backgroundColor : variables.yellow
        // },

        // '&.theme2' : {
        //     backgroundColor : variables.pink
        // },

        // '&.theme3' : {
        //     backgroundColor : variables.yellow
        // },

        // '&.theme4' : {
        //     backgroundColor : variables.blue
        // },

        // '&.theme5' : {
        //     backgroundColor : variables.orange
        // },

        // '&.theme6' : {
        //     backgroundColor : variables.blue
        // },

        flex: ['1 1 100%',null,'1 1 30%'],
        maxWidth: ['100%',null,'30%'],
        transition: 'all .2s ease-in-out',
        position: 'relative',
        '& .pageTile__bsyc__container': mq({
            //position: 'absolute',
            position: ['static,', null, 'absolute'],
            top: '50%',
            left: '50%',
            transform: ['none', null, 'translate(-50%, -50%)'],
            width: ['100%', null, '90%'],
            padding: ['1rem', null, '0px'],
            '& .pageTile__bsyc__title': {
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: '2rem',
                //textTransform: 'uppercase',
                //fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color:'black'
            },
            '& .pageTile__bsyc__logo__img' : {
                maxWidth: '90px',
                margin:'auto',
                marginTop: '1rem',
                'img' : {
                    width: '100%',
                    height: 'auto'
                }
            },
            '& .pageTile__bsyc__desc': {
                lineHeight: '1.3',
                fontSize: '.9rem',
                minHeight: '70px',
                marginTop: '1rem',
                color:'black'
            },
            '& .pageTile__bsyc__desc2': {
                lineHeight: '1.3',
                fontSize: '.9rem',
                marginTop: '1rem',
                //textDecoration:'underline',
                color:'black'
            }
        })
    }))
}