import { variables, mq } from '../../../cssInJs';

export default {

    atdwTile: mq({
        flexGrow: '1',
        flexShrink: '0',
        flexBasis: ['100%', null, '33.3%'],
        maxWidth: ['100%', null, '33.3%'],
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '0.7rem',
        paddingRight: '0.7rem',
        'a': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            '&:hover': {
                textDecoration: 'none',
                '.atdwitembg__container': {
                    transform: 'scale(1.05)'
                }
            }
        }
    }),

    bgContainer: {
        boxShadow: '3px 1px 12px rgba(0,0,0,0.5)',
        transition: 'all 0.2s',
        //padding:'0.3rem',
        backgroundColor:variables.lightGrayColor
    },

    bg: {
        paddingTop: '56.25%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // boxShadow: '3px 1px 12px rgba(0,0,0,0.7)',
        // transition: 'all 0.2s'
    },
    container: {
        position: 'relative',
        overflow: 'hidden',
        flexGrow: '1',
        backgroundColor: 'white',
        marginTop: '-20px',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '3px 1px 12px rgba(0,0,0,0.5)',
    },
    details: {
        padding: '1rem 0.8rem',
        flex: '1 0 auto',
        '&.more': {
            paddingTop: '0.5rem'
        }
    },
    pName: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        fontWeight: 700,
        lineHeight: 1,
        color: `${variables.blue} !important`,
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
    },
    hr: {
        width: '70% !important',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        borderTopColor: `black !important`,
        marginLeft: '0 !important',
        marginRight: '0 !important'
    },
    des: {
        lineHeight: '1.3',
        fontSize: '0.7rem',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        color: `${variables.fontColor} !important`
    },
    eventDate: {
        fontSize: '0.7rem',
        color: variables.sandCtoneColor,
        marginBottom: '0.2rem'
    },
    bottomImg: {
        width: '100%'
    }
}