import { mq, variables } from '../../../cssInJs';

export default {
    bannerConatiner : mq({
        margin: 'auto',
        marginTop: '1.5rem',
        maxWidth: '960px',
        paddingRight: '0px',
        paddingLeft: '0px'
    }),
    bannerItem : mq({
        //border : '1px solid red',
        'img' : mq({
            width: '100%'
        })
    }),
    backgroundBannerItem : mq({
        paddingTop: '56.25%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat'
    }),
    barBtnContainer : mq({
        display: 'flex',
        marginLeft: '-0.5rem',
        marginRight: '-0.5rem',
        marginTop:'0.3rem',
        flexWrap: 'wrap',
        justifyContent: ['center',null,'unset']
    }),
    barBtn : mq({
        lineHeight: '1',
        padding:['0.5rem 2rem',null,'0.3rem 2rem'],
        borderRadius: '30px',
        color: variables.orangeColor,
        backgroundColor: variables.sandCtoneColor,
        marginLeft:'0.5rem',
        marginRight:'0.5rem',
        fontSize: '0.8rem',
        marginTop: '0.2rem',
        marginBottom: '0.2rem',
        width: ['140px','unset']
        // flex: ['1 0 32%',null,'unset'],
        // maxWidth:['32%',null,'unset']
    }),
    hoverPanel : mq({
        //height: '30%',
        //backgroundColor: 'red',
        position: 'absolute',
        //overflow: 'hidden',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        zIndex: '-1'
    }),
    hoverPanelTop : mq({
        height: ['46%',null,'55%'],
        position: 'relative',
        //backgroundColor: variables.orangeColor,
        '&.one-line' : mq({
            height: ['41%',null,'55%']
        })
    }),
    productName : mq({
        //color: variables.orangeColor,
        lineHeight: '1.1',
        fontSize: '2.5rem',
        //paddingLeft: '0.5rem',
        //paddingRight: '0.5rem'
    }),
    productDes : mq({
        '&.des__0' : {
            fontWeight: 600
        }
    }),
    productRate : {
        fontWeight: 600,
        marginTop:'1.5rem',
        //marginBottom: '1rem'
    },
    expendablePanel : {
        marginTop: '1rem',
        '&.open' : {
            '.exPanelTitle svg' : {
                transform:'translateY(-50%) rotate(180deg)'
            },
            '.expendablePanelContent' : {
                opacity: '1',
                //height: '100%'
                height: 'auto'
            }
        }
    },
    expendablePanelTitle : {
        borderBottom: `1px solid`,
        cursor:'pointer',
        position:'relative',
        'svg' : {
            position:'absolute',
            right:'0px',
            top:'50%',
            transform: 'translateY(-50%)',
            transition: 'all 0.2s ease-in-out'
        }
    },
    expendablePanelContent : {
        transition: 'opacity 1s ease-out !important',
        opacity: '0',
        height: '0',
        overflow:'hidden'
    },
    expanleTitle : {
        fontWeight: 600,
    },
    content : {
        fontSize: '0.9rem'
    },
    searchFunctionText : {
        fontWeight: 600,
        marginTop:'3rem',
        marginBottom: '1rem'
    },
    searchTextTitle : {
        fontWeight: 600,
        fontSize: '2.2rem',
        textTransform: 'uppercase',
        color: variables.orangeColor,
        marginBottom: '1.5rem'
    }
}