import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/eat-and-drink', title: 'Eat & Drink', icon: 'eat-drink.png', iconHover: 'eat-drink.png' },
    { url: '/explore', title: 'Explore', icon: 'explore.png', iconHover: 'explore.png' },
    { url: '/plan', title: 'Plan', icon: 'plan.png', iconHover: 'plan.png' },
    { url: '/stay', title: 'Stay', icon: 'stay.png', iconHover: 'stay.png' }
    // { url: '/live', title: 'Live', icon: 'live.png', iconHover: 'live.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        {/* <div className='bottomMenu__div__1'>{link.title}</div> */}
        <div className='bottomMenu__link__container'>
          <div className="icon div">
            <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
            <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
          </div>
          <div className='bottomMenu__div__2 div'>{link.title}</div>
        </div>
      </SiteLink>
    </li>
  );

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      <div className='bottomMenu__stripe'>
        <div className='bottomMenu__stripe__inner'>
          {/* <img src='/assets/theme-01.png' /> */}
        </div>
      </div>
    </>
  );
}