/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({
    parentsTitle: "",
    openStatus: false,
    subItems: [],
  });

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
    setOpenSubMenu(resetOpenSubMenu());
  }

  function onCloseMenu() {
    setMenuOpened(false);
    setOpenSubMenu(resetOpenSubMenu());
  }

  function updateOpenSubMenu(data) {
    if (
      data.parentsTitle !== "" &&
      data.parentsTitle !== openSubMenu.parentsTitle
    ) {
      setOpenSubMenu({
        parentsTitle: data.parentsTitle,
        openStatus: true,
        subItems: data.subItems,
      });
      return;
    } else {
      setOpenSubMenu(resetOpenSubMenu());
      return;
    }
  }

  function resetOpenSubMenu() {
    const result = { parentsTitle: "", openStatus: false, subItems: [] };
    let _nList = document.querySelectorAll(
      ".main__nav__items li.navBar__item.navBar__item--open"
    );
    _nList.forEach((item) => {
      item.classList.remove("navBar__item--open");
    });
    return result;
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id='top'>
        <BackButton />
        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
        >
          <img
            css={style.logoImg}
            src='/assets/logos/logo.png'
            alt='logo'
            width='400'
            height='38'
          />
        </SiteLink>
        <SiteLink
          to='/'
          css={[style.logo2, scrolled && style.logoScrolled2]}
          onClick={backTop}
        >
          <img
            css={style.logoImg2}
            src='/assets/logos/logo2.png'
            alt='logo'
            width='401'
            height='108'
          />
        </SiteLink>
        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar
          open={menuOpened}
          onCloseMenu={onCloseMenu}
          scrolled={scrolled}
          openSubMenu={openSubMenu}
          updateOpenSubMenu={updateOpenSubMenu}
        ></NavBar>
      </header>
    </Fragment>
  );
}
