//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../..';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import Slider from "react-slick";

export function PageTilesSharkCageDiving2(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const tileType = "type" + (getPages(cmsUtils.payload(item, 'TileType')) || "1");

  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index} tileType={tileType} useSlider = {useSlider}></Tile>
  );

  //const cssClass = utils.classNames('cms_item', 'pageTiles tiles', item.cssClass || item.anchorName || '');
  const cssClass = utils.classNames('cms_item', 'pageTiles__sharkCage', (useSlider && tileType === 'type3') && 'useSlider', item.cssClass || item.anchorName || '');

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };



  if(useSlider && tileType === 'type2'){
    return(
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={style.slider}>
        <Slider {...settings} >
          {pageTileElts}
        </Slider>
      </div>
    );
  }
  else{
    return (
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
        css={style.pageTiles}>
        {pageTileElts}
      </div>
    );
  }
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const tileType = props.tileType;
  const useSlider = props.useSlider;
  const colorTheme = page.colorTheme ? (page.colorTheme === 'default' ? 'theme1' : page.colorTheme) : 'theme1';

  return (

    <div css={style.pageTile__tile} className={`pageTile__sharkCage ${tileType} ${useSlider && 'useSlider'}`}>
      <SiteLink className="pageTile__sharkCage__content" css={style.tile__content} to={disableLink ? '' : page.pageUrl}>
        <div className="pageTile__sharkCage__bg__wrapper" css={style.tile__bg__wrapper}>
          <div className="pageTile__sharkCage__bg" css={style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>
        
        <div className={`pageTile__sharkCage__details ${colorTheme}`} css={style.tile__details}>
          <div className="info-wrapper" css={style.info__wrapper}>
            
            <div className="info-title" css={style.info__title}>
              {page.pageTitle}
            </div>
            
            {/* <div className="info-des" css={style.info__des}>
              <div className="infoDesc" css={style.infoDesc}>
                {page.tileDesc || page.description}
              </div>
            </div> */}

          </div>
        </div>

      </SiteLink>
    </div>
  )
}