import React, { useEffect, Fragment } from 'react';
import { SiteLink } from '../../components';
import { useStore } from '../../store';
import { FaChevronRight } from 'react-icons/fa';
import { SearchBar } from './searchBar';

export function NavBar(props) {
  const open = props.open;
  const scrolled = props.scrolled;
  //const currentLocation = props.currentLocation;
  const { state } = useStore();

  const menu = state.menu || { items: [] };
  const currentLocation = state.history && state.history.length > 0 ? state.history[state.history.length - 1].path : '';
  const depth = 1;
  const menuItemElts = menu.items.map((item, index) =>
    <MenuItem item={item} key={index} depth={depth} index={index} onCloseMenu={props.onCloseMenu} currentLocation={currentLocation} updateOpenSubMenu={props.updateOpenSubMenu}></MenuItem>
  );

  const subItemsElts = props.openSubMenu.subItems.length > 0 ? props.openSubMenu.subItems.map((subItem, index) =>
    <SubMenuItem item={subItem} key={index} onCloseMenu={props.onCloseMenu} />
  ) : null;

  return (
    <Fragment>
      <div className={`navBar ${open ? 'navBar--on' : ''} ${props.openSubMenu.openStatus ? 'subNavBar--on' : ''} ${scrolled ? 'scrolled' : ''}`} id="sliding-menu">
        <SearchBar onCloseMenu={props.onCloseMenu} />
        <div className={`navBar__scrollArea ${scrolled ? 'scrolled' : ''}`} id="top-menu">
          <nav className="navBar_nav">
            <ul className='main__nav__items'>
              {menuItemElts}
            </ul>
          </nav>
        </div>

        <div className='nav__bar__social'>
          <div className='nav__bar__social__inner'>
            <a href="https://www.instagram.com/adelaidebeachessa" target='_blank' rel='noopener noreferrer'>
              <div className='result__step__social__container'>
                <img src='/sites/adelaidebeaches/media/miscellaneous/instagram-orange.png' alt="fb" width="40" height='40' />
              </div>
            </a>
            <a href="https://www.facebook.com/AdelaideBeachesSA" target='_blank' rel='noopener noreferrer' style={{ marginLeft: '10px' }}>
              <div className='result__step__social__container'> <img src='/sites/adelaidebeaches/media/miscellaneous/facebook-orange.png' alt="fb" width="40" height='40' /> </div>
            </a>
          </div>
        </div>

      </div>

      <div className={`nav__bar__sub__menu ${props.openSubMenu.openStatus ? 'subNavBar--on' : ''}`}>
        <div className={`navBar__scrollArea ${scrolled ? 'scrolled' : ''}`}>
          <nav className="navBar_nav">
            <ul>
              {subItemsElts}
              <li className="navBar__item">
                <div className="navBar__link back__button" onClick={() => {
                  props.updateOpenSubMenu({ parentsTitle: '' })
                }}>
                  <span> Back </span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Fragment>

  );
}

function MenuItem(props) {

  const item = props.item;
  const subItems = item.items || [];
  const currentLocation = props.currentLocation;
  const updateOpenSubMenu = props.updateOpenSubMenu;
  const index = props.index;

  function resetOpenStatus() {
    let _node = document.querySelector('.main__nav__items li.navBar__item.navBar__item__' + index);
    _node.classList.remove("navBar__item--open");
  }

  useEffect(() => {
    resetOpenStatus();

    // eslint-disable-next-line
  }, [currentLocation])

  function closeMenu() {
    props.onCloseMenu();
  }

  function toggleSubmenu(e, data) {
    e.preventDefault();
    e.stopPropagation();

    let _node = document.querySelector('.main__nav__items li.navBar__item.navBar__item__' + index);
    if (_node.classList.contains('navBar__item--open')) {
      resetOpenStatus();
    }
    else {
      _node.classList.add('navBar__item--open');
    }
    updateOpenSubMenu(data);
  }

  return (
    <li className={`navBar__item ${'navBar__item__' + index}`}>
      <SiteLink className="navBar__link" to={item.url} onClick={() => closeMenu()}>
        <span>{item.title}</span>
        {
          subItems.length > 0 &&
          <span className="navBar__icon" onClick={(e) => toggleSubmenu(e, { parentsTitle: item.title, subItems: subItems })}><FaChevronRight /></span>
        }
      </SiteLink>
    </li>
  )
}

function SubMenuItem(props) {
  const item = props.item;

  function closeMenu() {
    props.onCloseMenu();
  }

  return (
    <li className="navBar__item">
      <SiteLink className="navBar__link" to={item.url} onClick={() => closeMenu()}>
        <span>{item.title}</span>
      </SiteLink>
    </li>
  )
}