import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';

export function BlogPosts(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  console.log('item', item);

  const posts = getPages(cmsUtils.payload(item, 'BlogPosts'));
  const numOfTileLines = parseInt(cmsUtils.payload(item, 'NumOfTileLines', '2'));
  //console.log(numOfPost, parseInt(numOfPost));
  //console.log(posts);
  const bp = utils.ui.getCurrentBreakpoint();
  const tilesPerLine = { xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }
  const numOfPost = numOfTileLines * (tilesPerLine[bp] || 3);
  const step = numOfPost;

  const tileType = cmsUtils.payload(item, 'TileType') || '1';

  const [tilesToShow, setTilesToShow] = React.useState(numOfPost);

  const displayingPosts = posts.length > tilesToShow ? posts.slice(0, tilesToShow) : posts;
  const showMore = posts.length > displayingPosts.length;

  const pageTileElts = tileType === '1' ? 
    displayingPosts.map((page, index) => <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>) :
    displayingPosts.map((page, index) => <Tile2 page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile2>);

  const cssClass = utils.classNames('cms_item', 'blogTiles tiles', item.cssClass || item.anchorName || '');

  return (
    <>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        {pageTileElts}
      </div>
      {showMore && (<div className="blogTiles__loadMore">
        <span className="thinBtn" onClick={(e) => { setTilesToShow(tilesToShow + step) }}>LOAD MORE</span></div>)}
    </>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const postDate = utils.date.formatDDMMYYYY(page.postDate);

  return (
    <div className="blogTile">
      <SiteLink className="blogTile__link" to={disableLink ? '' : page.pageUrl}>
        <div className="blogTile__bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        <div className="blogTile__content">
          <div className="blogTile__contentInner">
            <div className="blogTile__title">
              {page.pageTitle}
            </div>
            {/* <div className="blogTile__desc">
              {page.tileDesc || page.description}
            </div> */}
            <div>
              {postDate}
            </div>
            <div className="blogTile__readMore">READ MORE</div>
          </div>
        </div>
      </SiteLink>
    </div>
  )
}

function Tile2(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  //const postDate = utils.date.formatDDMMYYYY(page.postDate);

  return (
    <div className="blogTile">
      <SiteLink className="blogTile__link2" to={disableLink ? '' : page.pageUrl}>
        <div className="blogTile__bg2" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl)}}></div>
        <div className="blogTile__content2">
          <div className="blogTile__contentInner2">
            <div className="blogTile__title2">
              {page.pageTitle}
            </div>
            {/* <div className="blogTile__readMore2">READ MORE</div> */}
          </div>
        </div>
      </SiteLink>
    </div>
  )
}



//'https://adelaidebeaches.stg5.jaba.com.au/sites/adelaidebeaches/media/banners/blog-banners/sotos-banner.jpg'