//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
  pageTiles: css({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden'
  }),

  slider: css({
    '.slick-prev': css(mq({
      left: ['0px !important', null, '-20px !important'],
    })),
    '.slick-next': css(mq({
      right: ['0px !important', null, '-20px !important']
    })),
    '.slick-next:before, .slick-prev:before': css({
      color: 'rgb(31, 79, 156)'
    })
  }),

  pageTile__tile: css(mq({
    flexGrow: '1',
    flexShrink: '1',
    padding: '10px',

    flexBasis: ['100%', null, '50%','33.3%', '25%'],
    maxWidth: ['100%', null, '50%','33.3%', '25%'],

    //For type3
    '&.type3': css(mq({
      '&.type3.useSlider': css({
        flexBasis: '100%',
        maxWidth: '100%',
        height: '100%'
      })
    }))
    
  })),

  tile__content: css({
    '&:hover': {
      textDecoration: 'none',
      '.pageTile__sharkCage__bg': css({
        //transform: 'scale(1.05)'
      }),
      '.pageTile__sharkCage__details' : {
        backgroundColor: variables.lightBlue
      },
      '.info-wrapper': {
        '.info-title, .info-des': {
          color : 'black',
          textDecoration : 'underline'
        }
      }
    },
    color: '#231f20',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }),

  tile__bg__wrapper: css({
    //height:'80%',
    overflow: 'hidden',
    flex: '0 0 auto'
  }),

  tile__bg: css(mq({
    paddingTop: ['100%'],
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: '.5s',
    borderRadius: '100% 100% 0 0'
  })),

  tile__details: css(mq({
    //padding: ['0 18px', '0 20px', '0 20px', '0 20px', '0 20px'],
    //height: '250px',
    //marginTop: ['-58px', null, '-38px', '-52px', '-56px'],
    //marginTop: ['-11.5%', -58, -37, -50, -56],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
    padding: '1rem 1rem',
    backgroundColor: variables.blue,
    // '&.theme1': {
    //   backgroundColor: variables.yellow,
    // },
    // '&.theme2': {
    //   backgroundColor: variables.blue,
    // },
    // '&.theme3': {
    //   backgroundColor: variables.yellow,
    // },
    // '&.theme4': {
    //   backgroundColor: variables.blue,
    // },
    // '&.theme5': {
    //   backgroundColor: variables.orange,
    // },
    // '&.theme6': {
    //   backgroundColor: variables.blue,
    // }
  })),
  info__wrapper: css({
    //backgroundColor: 'black',
    padding: '0',
    //paddingBottom: '1rem',
    //boxShadow: '5px 5px 5px #ebebeb',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    position: 'relative',
    justifyContent : 'center',
    minHeight : '44px'
    // '> div' : css({
    //      //marginTop: '0.5rem',
    //      //marginBottom: '0.5rem'
    // })
    // '&::after': {
    //   content: '""',
    //   width: '100%',
    //   height: '77%',
    //   position: 'absolute',
    //   top: '23%',
    //   left: '0px',
    //   backgroundColor: 'transparent',
    //   //boxShadow: '5px 5px 5px #ebebeb'
    // }
  }),
  info__title: css({
    //fontWeight: '900',
    //color: variables.redColor,
    fontSize: '1rem',
    lineHeight: 1.1,
    marginLeft: 'auto',
    marginRight: 'auto',
    color : 'black',
    textAlign : 'center'
  }),
  info__des: css({
    //marginBottom: '0.4rem',
    //overflow: 'hidden',
    //maxHeight: '160px',
    //flex: '1 1 auto',
    fontSize: '0.8rem',
    flexGrow: 1,
    marginTop:'2rem'
  }),
  infoDesc: {
    display: '-webkit-box',
    WebkitLineClamp: '6',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'center'
  }
}