import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: ['white',null,'transparent'],
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    //height: [44, null, 56],
    background: 'white !important'
  })),
  logo: css(mq({
    display: ['block',null,'none'],
    transition: 'all 0.3s'
  })),
  logoImg: css(mq({
    height: [22, null, 36],
    width : 'auto'
  })),
  logoScrolled: css(mq({
    display: 'block !important',
    img : css(mq({
      // height: [38, null, 50],
      // marginTop:'0rem'
    }))
  })),

  logo2: css(mq({
    display: ['none',null,'block'],
    transition: 'all 0.3s',
    position: 'fixed',
    top : '2%',
    left : '3%'
  })),

  logoImg2: css(mq({
    height: [22, null, 75],
    width : 'auto'
  })),

  logoScrolled2: css(mq({
    display: 'none !important',
    img : css(mq({
      // height: [38, null, 50],
      // marginTop:'0rem'
    }))
  })),


}